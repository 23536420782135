
// Staging
export const API_ENDPOINT = "https://eventmanagement-api.staging.nowbst.com/add-on/event-management"
export const API_ENDPOINT_LOCATION = "https://donorapp-apiqa.cslplasma.com/external/location/getAllSavable"
// drupal cslplasma Staging
export const API_ENDPOINT_CSL_PLASMA = "http://34.192.48.173"

// QA
// export const API_ENDPOINT = "https://donorapp-apiqa.cslplasma.com/add-on/event-management"
// export const API_ENDPOINT_LOCATION = "https://donorapp-apiqa.cslplasma.com/external/location/getAllSavable"
// // // drupal cslplasma QA
// export const API_ENDPOINT_CSL_PLASMA = "https://qa08.cslplasma.com"

// Production
// export const API_ENDPOINT = "https://donorapp-api.cslplasma.com/add-on/event-management"
// export const API_ENDPOINT_LOCATION = "https://donorapp-api.cslplasma.com/external/location/getAllSavable"
// // drupal cslplasma Production
// export const API_ENDPOINT_CSL_PLASMA = "https://www.cslplasma.com"